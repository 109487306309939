<template>
    <div class="community-page bg-light">
        <section class="page-kv">
            <!-- 桌機圖 -->
            <img :src="image.bannerPc" :alt="meta.alt.alt_7_1" class="w-100 d-none d-md-inline-block" v-if="image.bannerPc != ''">
            <!-- 手機圖 -->
            <img :src="image.bannerMobile" :alt="meta.alt.alt_7_1" class="w-100 d-inline-block d-md-none" v-if="image.bannerMobile != ''">
        </section>

        <div class="collection-search d-none d-md-block mt-negative bg-wh">
            <div class="container">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/">首頁</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">社區大樓</li>
                    </ol>
                </nav>
                <div class="search-panel" v-if="width > 767">
                    <div class="searchTab-content tab-content">
                        <div class="" id="searchAll" role="tabpanel">
                            <div class="search-form-wrapper">
                                <div class="form-group form-search-group">
                                    <label class="visually-hidden" for="searchInput">關鍵字搜尋</label>
                                    <i class="icon">
                                        <IconSearch/>
                                    </i>
                                    <input type="text" class="form-control rounded-input" id="searchInput" placeholder="請輸入路名、社區名稱" @keyup="changeChecked()" v-model="keyword">
                                </div>

                                <div class="row g-2 mb-2">
                                    <div class="form-group dropdown-wrapper col-auto dropdown-1">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(1)">
                                            <div :class="checkedCity != 0 ? 'btn btn-select selected' : 'btn btn-select' ">
                                                地區
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div :class='"select-dropdown region_dropdown p-0 " + (townList.length > 0 ? "show_result" : "")'>
                                            <div class='region_dropdown-row'>
                                                <ul id="accordionRegion" class="accordion_region accordion accordion-flush region-list">
                                                    <template v-for="(regionName, regionIndex) in regionList">
                                                        <li class="item" role="presentation" v-if="cityList[regionIndex].length > 0">
                                                            <button type="button" :class="'nav-link' + (regionIndex == 0 ? ' active' : '')" id="north-tab" :region="regionIndex"
                                                                    data-bs-toggle="collapse"
                                                                    :data-bs-target="'#region-' + regionIndex" :aria-expanded="regionIndex == 0 ? 'true' : 'false'"
                                                                    aria-controls="north" :aria-selected="regionIndex == 0 ? 'true' : 'false'">
                                                                {{ regionName }}
                                                                <i class="icon">
                                                                    <IconSortDown class="down"/>
                                                                    <IconSortUp class="up"/>
                                                                </i>
                                                            </button>
                                                            <div :id="'region-' + regionIndex" :class="'accordion-collapse collapse' + (regionIndex == 0 ? ' show' : '')"
                                                                 data-bs-parent="#accordionRegion">
                                                                <div class="accordion-body pt-1">
                                                                    <div class="row gx-3 gy-2">
                                                                        <div class="col-6" v-for="(city, index) in cityList[regionIndex]">
                                                                            <button :class="'btn btn-checkbox w-100 ' + city.active + city.selected" data-name="city"
                                                                                    :id="'city-' + regionIndex +'-' + index" @click="getTown(city)">
                                                                                {{ city.city }}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </template>
                                                </ul>
                                                <div class="result_region" v-if="townList.length > 0">
                                                    <div class="form-check form-check-inline" v-if="townList.length > 1">
                                                        <input class="form-check-input" type="checkbox" id="townUnlimited" value="1" @click="changeTownUnlimited()"
                                                               v-model="townUnlimited">
                                                        <label class="form-check-label" for="townUnlimited">全選</label>
                                                    </div>
                                                    <hr>
                                                    <div class="row g-1">
                                                        <div class="col-4" v-for="(town, index) in townList">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" name="town[]" :id="'town-' + index" :value="town"
                                                                       @click="changeTown();" v-model="checkedTown">
                                                                <label class="form-check-label" :for="'town-' + index">{{ town }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-2">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(2)">
                                            <div :class="checkedCommunityType.length > 0 ? 'btn btn-select selected' : 'btn btn-select' ">
                                                型態
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="form-content">
                                                <div class="row g-1">
                                                    <div class="col-4" v-for="(communityType, index) in communityTypeList">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" name="communityType[]" :id="'communityType-' + index"
                                                                   :value="communityType" v-model="checkedCommunityType" @click="changeCommunityType">
                                                            <label class="form-check-label" :for="'communityType-' + index">{{ communityType }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-3">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(3)">
                                            <div :class="checkedPrice.length > 0 ? 'btn btn-select selected' : 'btn btn-select' ">
                                                總價
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="row g-1">
                                                <div class="col-6" v-for="(price, index) in priceList">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" name="price[]" :id="'price-' + index" :value="index + 1"
                                                               @click="changePrice()" v-model="checkedPrice">
                                                        <label class="form-check-label" :for="'price-' + index">{{ price }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="other-input-group d-flex align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="price-9" value="9" v-model="checkedPrice">
                                                            <label class="form-check-label" for="price-9"></label>
                                                        </div>
                                                        <div class="start">
                                                            <input type="text" class="form-control form-control-sm" @focus="focusPriceOther" @keyup="changePrice()"
                                                                   v-model="priceS">
                                                        </div>
                                                        <span class="px-2">-</span>
                                                        <div class="end">
                                                            <input type="text" class="form-control form-control-sm" @focus="focusPriceOther" @keyup="changePrice()"
                                                                   v-model="priceE">
                                                        </div>
                                                        <span class="px-2">萬</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-4" v-if="category != 2">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(4)">
                                            <div :class="checkedAveragePrice.length > 0 ? 'btn btn-select selected' : 'btn btn-select' ">
                                                單價
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="row g-1">
                                                <div class="col-3" v-for="(averagePrice, index) in averagePriceList">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" name="averagePrice" :id="'averagePrice-' + index" :value="index + 1"
                                                               @click="changeAveragePrice()"
                                                               v-model="checkedAveragePrice">
                                                        <label class="form-check-label" :for="'averagePrice-' + index">{{ averagePrice }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="other-input-group d-flex align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="averagePrice-6" value="6" v-model="checkedAveragePrice">
                                                            <label class="form-check-label" for="averagePrice-6"></label>
                                                        </div>
                                                        <div class="start">
                                                            <input type="text" class="form-control form-control-sm" @focus="focusAveragePriceOther" @keyup="changeAveragePrice()"
                                                                   v-model="averagePriceS">
                                                        </div>
                                                        <span class="px-2">-</span>
                                                        <div class="end">
                                                            <input type="text" class="form-control form-control-sm" @focus="focusAveragePriceOther" @keyup="changeAveragePrice()"
                                                                   v-model="averagePriceE">
                                                        </div>
                                                        <span class="px-2">萬</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group dropdown-wrapper col-auto dropdown-5" v-if="category != 2">
                                        <div class="d-grid gap-2" @click="handleOpenSelect(5)">
                                            <div :class="checkedAge.length > 0 ? 'btn btn-select selected' : 'btn btn-select' ">
                                                屋齡
                                                <i class="icon">
                                                    <IconSortDown class="down"/>
                                                    <IconSortUp class="up"/>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="select-dropdown">
                                            <div class="row g-1">
                                                <div class="col-4" v-for="(age, index) in ageList">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" :id="'age-' + index" :value="index + 1" @click="changeChecked()"
                                                               v-model="checkedAge">
                                                        <label class="form-check-label" :for="'age-' + index">{{ age }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="other-input-group d-flex align-items-center">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" id="age-6" value="6" v-model="checkedAge">
                                                            <label class="form-check-label" for="age-6"></label>
                                                        </div>
                                                        <div class="start">
                                                            <input type="text" class="form-control form-control-sm" @focus="focusAgeOther" @keyup="changeChecked()"
                                                                   v-model="ageS">
                                                        </div>
                                                        <span class="px-2">-</span>
                                                        <div class="end">
                                                            <input type="text" class="form-control form-control-sm" @focus="focusAgeOther" @keyup="changeChecked()"
                                                                   v-model="ageE">
                                                        </div>
                                                        <span class="px-2">年</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="search-tags pt-3">
                                    <div class="d-flex align-items-center mb-2">
                                        <h4 class="title me-2 mb-0">已選條件({{ searchTagList.length }})</h4>
                                        <button type="button" class="btn clear-all" @click="clear()" v-if="searchTagList.length > 0">
                                            <span class="text">清除全部</span>
                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M1.77631 13.462C1.77631 14.3203 2.47859 15.0226 3.33693 15.0226H9.5794C10.4377 15.0226 11.14 14.3203 11.14 13.462V4.09828H1.77632V13.462H1.77631ZM11.9203 1.75736H9.18924L8.40893 0.977051H4.50739L3.72708 1.75736H0.996002V3.31798H11.9203V1.75736Z"
                                                      fill="#999999"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="result-tags">
                                        <div class="btn btn-tags" v-for="(tag, index) in searchTagList">
                                            {{ tag.value }}
                                            <button class="close-circle" type="button" @click="deleteTag(index)"><i class="icon">
                                                <IconClose/>
                                            </i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="collection-search-mobile" v-if="width < 768">
            <div class="container" id="mobileSearchContainer">
                <div class="search-group">
                    <div class="form-group form-search-group mb-3">
                        <i class="icon">
                            <IconSearch/>
                        </i>
                        <input type="text" class="form-control search-input" placeholder="請輸入物件編號、物件名稱、路名..." @keyup="changeChecked()" v-model="keyword">
                    </div>
                    <div class="btn-wrapper">
                        <button type="button" class="btn btn-primary w-100 big-r" data-bs-toggle="modal" data-bs-target="#mobileSearchModal">篩選條件</button>
                    </div>
                    <hr class="mb-0 mt-3 search-tags-hr">
                    <div class="search-tags pt-3">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                            <div class="d-flex align-items-center">
                                <h4 class="title mb-0">已選條件({{ searchTagList.length }})</h4>
                                <button type="button" class="btn clear-all" @click="clear()" v-if="searchTagList.length > 0">
                                    <span class="text">清除全部</span>
                                    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M1.77631 13.462C1.77631 14.3203 2.47859 15.0226 3.33693 15.0226H9.5794C10.4377 15.0226 11.14 14.3203 11.14 13.462V4.09828H1.77632V13.462H1.77631ZM11.9203 1.75736H9.18924L8.40893 0.977051H4.50739L3.72708 1.75736H0.996002V3.31798H11.9203V1.75736Z"
                                              fill="#999999"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="ml-auto btn btn-collapse" v-if="searchTagList.length > 0" data-bs-toggle="collapse" href="#mobileResultTags" role="button"
                                 aria-expanded="true" aria-controls="mobileResultTags">
                                <i class="icon">
                                    <IconSortDown class="down"/>
                                    <IconSortUp class="up"/>
                                </i>
                            </div>
                        </div>
                        <div class="result-tags collapse show" id="mobileResultTags">
                            <div class="btn btn-tags" v-for="(tag, index) in searchTagList">
                                {{ tag.value }}
                                <button class="close-circle" type="button" @click="deleteTag(index)"><i class="icon">
                                    <IconClose/>
                                </i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="collections pb-5">
            <!--            <div class="container px-smdown-40">-->
            <div class="container">
                <div class="form-check d-block d-sm-none">
                    <input class="form-check-input" type="checkbox" name="isSale[]" id="isSale-1" value="1" @click="search(0)" v-model="isSale">
                    <label class="form-check-label" for="isSale-1">只顯示有物件銷售中的社區</label>
                </div>
                <div class="collections-view-filter d-flex align-items-center justify-content-between mb-2 mb-md-4">
                    <div class="d-flex align-items-center">
                        <h4 id="totalRecord" class="total_record_title mb-0">社區筆數：{{ totalRecord }}</h4>
                        <div class="form-check ms-4 mb-0 d-none d-sm-block">
                            <input class="form-check-input" type="checkbox" name="isSale[]" id="isSale-2" value="1" @click="search(0)" v-model="isSale">
                            <label class="form-check-label" for="isSale-2">只顯示有物件銷售中的社區</label>
                        </div>
                    </div>
                    <div class="view-switch ms-auto mb-0">
                        <button @click="changeMode(1)" :class="mode == 1 ? 'btn btn-link active' : 'btn btn-link'">
                            <IconMap class="me-0 me-md-2"/>
                            <span class="text d-none d-md-inline-block">地圖<span class="d-none d-lg-inline-block">模式</span></span>
                        </button>
                        <button @click="changeMode(2)" :class="mode == 2 ? 'btn btn-link sm-border active' : 'btn btn-link sm-border'">
                            <IconGrid class="me-0 me-md-2"/>
                            <span class="text d-none d-md-inline-block">列表<span class="d-none d-lg-inline-block">模式</span></span>
                        </button>
                        <div class="dropdown ms-lg-3">
                            <button class="btn btn-link d-block d-lg-none" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <IconSort/>
                            </button>
                            <button class="btn btn-select d-none d-lg-inline-flex" type="button" id="dropdownSort" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ orderByList[orderBy] }}
                                <i class="icon">
                                    <IconSortDown class="down"/>
                                    <IconSortUp class="up"/>
                                </i>
                            </button>
                            <ul class="dropdown-menu select-dropdown" aria-labelledby="dropdownSort">
                                <li><a :class="'dropdown-item' + (orderByIndex == orderBy ? ' active' : '')" href="javascript:;" v-for="(orderByName, orderByIndex) in orderByList"
                                       @click="changeOrderBy(orderByIndex)">{{ orderByName }}</a></li>
                                <!--                                <li><a class="dropdown-item active" href="javascript:;">成交量高到低</a></li>-->
                                <!--                                <li><a class="dropdown-item" href="javascript:;">平均單價低到高</a></li>-->
                                <!--                                <li><a class="dropdown-item" href="javascript:;">平均單價高到低</a></li>-->
                                <!--                                <li><a class="dropdown-item" href="javascript:;">屋齡從低到高</a></li>-->
                                <!--                                <li><a class="dropdown-item" href="javascript:;">屋齡從高到低</a></li>-->
                                <!--                                <li><a class="dropdown-item" href="javascript:;">銷售中物件多到少</a></li>-->
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- 地圖模式 -->
                <div :class="'map-view colleciotn-map-view community' + (mode == 1 ? '' : ' d-none')">
                    <div class="map-content">
                        <div id="map"></div>
                    </div>
                    <div class="map-list-group community-group" v-if="width >= 992">
                        <div class="community-map-list">
                            <CommunityMap :community="community" :markerChecked="markerChecked" :showItem="showItem" v-for="community in communityList"></CommunityMap>
                        </div>
                    </div>
                    <div class="map-list-group community-group" v-if="width < 992">
                        <CommunityMap :community="community" :markerChecked="markerChecked" :showItem="showItem" v-for="community in communityList"></CommunityMap>
                    </div>
                </div>

                <!-- 列表模式 -->
                <div class="community-lists-view" v-if="mode == 2">
                    <div class="community-lists-group">
                        <CommunityItem :community="community" v-for="community in communityList"></CommunityItem>
                    </div>
                    <nav aria-label="Page navigation" v-html="paginate"></nav>
                </div>
            </div>
        </section>
        <div class="modal fade" id="mobileSearchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="width < 768">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title w-100 text-center" id="exampleModalLabel">篩選條件</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul id="accordionMobile" class="accordion_mobile accordion accordion-flush region-list">
                            <li class="item" role="presentation">
                                <button type="button" class="nav-link w-100 active" region="0" data-bs-toggle="collapse" data-bs-target="#condition1" aria-expanded="false"
                                        aria-controls="north" aria-selected="false">縣市
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedCity != 0">{{ checkedCity }}</span>
                                        <span class="condition-text" v-else>不限</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition1" class="accordion-collapse collapse " data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <button class="btn btn-checkbox me-2 mb-2 " v-for="(city, index) in cityList[0]" :id="'city-0-' + index" :key="'city-0-' + index"
                                                @click="getTown(city)">
                                            {{ city.city }}
                                        </button>
                                        <button class="btn btn-checkbox me-2 mb-2 " v-for="(city, index) in cityList[1]" :id="'city-1-' + index" :key="'city-0-' + index"
                                                @click="getTown(city)">
                                            {{ city.city }}
                                        </button>
                                        <button class="btn btn-checkbox me-2 mb-2 " v-for="(city, index) in cityList[2]" :id="'city-2-' + index" :key="'city-0-' + index"
                                                @click="getTown(city)">
                                            {{ city.city }}
                                        </button>
                                        <button class="btn btn-checkbox me-2 mb-2 " v-for="(city, index) in cityList[3]" :id="'city-3-' + index" :key="'city-0-' + index"
                                                @click="getTown(city)">
                                            {{ city.city }}
                                        </button>

                                        <!-- <div class="form-check form-check-inline me-2 mb-2 btn-checkbox" v-for="(city, index) in cityList[0]">
                                            <input class="form-check-input" type="radio" name="city" :id="'city-0-' + index" :value="city.city" @click="getTown(city)"
                                                   v-model="checkedCity">
                                            <label class="form-check-label" :for="'city-0-' + index">{{ city.city }}</label>
                                        </div>
                                        <div class="form-check form-check-inline me-2 mb-2 btn-checkbox" v-for="(city, index) in cityList[1]">
                                            <input class="form-check-input" type="radio" name="city" :id="'city-1-' + index" :value="city.city" @click="getTown(city)"
                                                   v-model="checkedCity">
                                            <label class="form-check-label" :for="'city-1-' + index">{{ city.city }}</label>
                                        </div>
                                        <div class="form-check form-check-inline me-2 mb-2 btn-checkbox" v-for="(city, index) in cityList[2]">
                                            <input class="form-check-input" type="radio" name="city" :id="'city-2-' + index" :value="city.city" @click="getTown(city)"
                                                   v-model="checkedCity">
                                            <label class="form-check-label" :for="'city-2-' + index">{{ city.city }}</label>
                                        </div>
                                        <div class="form-check form-check-inline me-2 mb-2 btn-checkbox" v-for="(city, index) in cityList[3]">
                                            <input class="form-check-input" type="radio" name="city" :id="'city-3-' + index" :value="city.city" @click="getTown(city)"
                                                   v-model="checkedCity">
                                            <label class="form-check-label" :for="'city-3-' + index">{{ city.city }}</label>
                                        </div> -->
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" region="1" type="button" data-bs-toggle="collapse" data-bs-target="#condition2" aria-expanded="false"
                                        aria-controls="central" aria-selected="false" ref="townList">區域
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedTown.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedTown.length == 1">{{ checkedTown[0] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedTown.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition2" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="form-check mb-3" v-if="townList.length > 1">
                                            <input class="form-check-input" type="checkbox" id="townUnlimited" value="1" @click="changeTownUnlimited()" v-model="townUnlimited">
                                            <label class="form-check-label" for="townUnlimited" @click="changeChecked()">全選</label>
                                        </div>
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(town, index) in townList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="town[]" :id="'town-m-0-' + index" :value="town" @click="changeChecked()"
                                                           v-model="checkedTown">
                                                    <label class="form-check-label" :for="'town-m-0-' + index" @click="changeTown()">{{ town }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" region="2" type="button" data-bs-toggle="collapse" data-bs-target="#condition3" aria-expanded="false"
                                        aria-controls="south" aria-selected="false">型態
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedCommunityType.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedCommunityType.length == 1">{{ checkedCommunityType[0] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedCommunityType.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition3" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(communityType, index) in communityTypeList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="communityType[]" :id="'communityType-' + index"
                                                           :value="communityType"
                                                           v-model="checkedCommunityType" @click="changeCommunityType">
                                                    <label class="form-check-label" :for="'communityType-' + index" @click="changeChecked()">{{ communityType }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation">
                                <button class="nav-link w-100" region="3" type="button" data-bs-toggle="collapse" data-bs-target="#condition4" aria-expanded="false"
                                        aria-controls="east" aria-selected="false">總價
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedPrice.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedPrice.length == 1">{{ priceList[checkedPrice[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedPrice.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition4" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(price, index) in priceList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="price[]" :id="'price-' + index" :value="index + 1" @click="changePrice()"
                                                           v-model="checkedPrice">
                                                    <label class="form-check-label" :for="'price-' + index" @click="changeChecked()">{{ price }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="price-9" value="9" v-model="checkedPrice">
                                                        <label class="form-check-label" for="price-9"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="focusPriceOther" @keyup="changePrice()" v-model="priceS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="focusPriceOther" @keyup="changePrice()" v-model="priceE">
                                                    </div>
                                                    <span class="px-2">萬</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation" v-if="category != 2">
                                <button class="nav-link w-100" region="2" type="button" data-bs-toggle="collapse" data-bs-target="#condition5" aria-expanded="false"
                                        aria-controls="south" aria-selected="false">單價
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedAveragePrice.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedAveragePrice.length == 1">{{ averagePriceList[checkedAveragePrice[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedAveragePrice.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition5" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(averagePrice, index) in averagePriceList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="averagePrice" :id="'averagePrice-' + index" :value="index + 1"
                                                           @click="changeAveragePrice()"
                                                           v-model="checkedAveragePrice">
                                                    <label class="form-check-label" :for="'averagePrice-' + index" @click="changeChecked()">{{ averagePrice }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="averagePrice-6" value="6" v-model="checkedAveragePrice">
                                                        <label class="form-check-label" for="averagePrice-6"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="focusAveragePriceOther" @keyup="changeAveragePrice()"
                                                               v-model="averagePriceS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="focusAveragePriceOther" @keyup="changeAveragePrice()"
                                                               v-model="averagePriceE">
                                                    </div>
                                                    <span class="px-2">萬</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="item" role="presentation" v-if="category != 2">
                                <button class="nav-link w-100" region="2" type="button" data-bs-toggle="collapse" data-bs-target="#condition6" aria-expanded="false"
                                        aria-controls="south" aria-selected="false">屋齡
                                    <div class="acc-btn-right">
                                        <span class="condition-text" v-if="checkedAge.length == 0">不限</span>
                                        <span class="condition-text" v-else-if="checkedAge.length == 1">{{ ageList[checkedAge[0] - 1] }}</span>
                                        <span class="condition-text badge bg-primary me-1" v-else>{{ checkedAge.length }}</span>
                                        <i class="icon">
                                            <IconSortDown class="down"/>
                                            <IconSortUp class="up"/>
                                        </i>
                                    </div>
                                </button>
                                <div id="condition6" class="accordion-collapse collapse" data-bs-parent="#accordionMobile">
                                    <div class="accordion-body px-2">
                                        <div class="row g-2">
                                            <div class="col-6" v-for="(age, index) in ageList">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" :id="'age-' + index" :value="index + 1" @click="changeChecked()"
                                                           v-model="checkedAge">
                                                    <label class="form-check-label" :for="'age-' + index" @click="changeChecked()">{{ age }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex">
                                                <div class="other-input-group d-inline-flex align-items-center">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" id="age-6" value="6" v-model="checkedAge">
                                                        <label class="form-check-label" for="age-6"></label>
                                                    </div>
                                                    <div class="start">
                                                        <input type="text" class="form-control form-control-sm" @focus="focusAgeOther" @keyup="changeChecked()" v-model="ageS">
                                                    </div>
                                                    <span class="px-2">-</span>
                                                    <div class="end">
                                                        <input type="text" class="form-control form-control-sm" @focus="focusAgeOther" @keyup="changeChecked()" v-model="ageE">
                                                    </div>
                                                    <span class="px-2">年</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer pt-1">
                        <div class="row g-2 w-100">
                            <div class="col-6">
                                <button class="btn btn-outline-secondary clear_result w-100" type="button" @click="clear">清空條件</button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary w-100 check_result" type="button" data-bs-dismiss="modal" @click="hideCollapse(); search(0); track('店頭-搜尋物件', '');">
                                    查看結果({{ totalRecord }})
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import CommunityMap from '@/components/CommunityMap.vue'
import CommunityItem from '@/components/CommunityItem.vue'
import ArrRight from '@/components/icons/ArrRight.vue'
import ArrDown from '@/components/icons/ArrDown.vue'
import IconPin from '@/components/icons/IconPin.vue'
import HouseCard from '@/components/HouseCard.vue'
import IconClose from '@/components/icons/IconClose.vue'
import IconSortUp from '@/components/icons/IconSortUp.vue'
import IconSortDown from '@/components/icons/IconSortDown.vue'
import IconSearch from '@/components/icons/IconSearch.vue'
import IconMap from '@/components/icons/IconMap.vue'
import IconGrid from '@/components/icons/IconGrid.vue'
import IconSort from '@/components/icons/IconSort.vue'

import "leaflet/dist/leaflet.css"
import L from 'leaflet';
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import "leaflet.markercluster";

export default {
    name: 'Community',
    components: {
        CommunityMap,
        CommunityItem,
        ArrRight,
        ArrDown,
        IconPin,
        IconClose,
        IconSortUp,
        IconSearch,
        IconSortDown,
        HouseCard,
        IconMap,
        IconGrid,
        IconSort
    },
    data() {
        return {
            siteName: '',
            meta: {},
            about: {},
            searchTagList: [],
            width: 0,
            currentPage: 0,
            category: 0,
            image: {
                bannerPc: '',
                bannerMobile: '',
            },
            region: 0,
            regionList: [],
            cityList: [],
            townUnlimited: 0,
            townList: [],
            communityTypeList: [],
            priceList: [],
            averagePriceList: [],
            ageList: [],
            orderByList: [],
            communityList: [],
            areaList: [],
            isSale: [],
            orderBy: 0,
            totalRecord: '',
            paginate: '',
            checkedCity: 0,
            checkedTown: [],
            checkedCommunityType: [],
            checkedPrice: [],
            priceS: '',
            priceE: '',
            checkedAveragePrice: [],
            averagePriceS: '',
            averagePriceE: '',
            checkedAge: [],
            ageS: '',
            ageE: '',
            keyword: '',
            mode: 1,
            showItem: [],

            map: '',
            marker: '',
            divIcon: [],
            markerChecked: 0,

            leaflet: {
                zoom: 16,
                center: [22.612961, 120.304167],
                houseLatLng: [22.612961, 120.304167],
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                attribution: `© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
                options: {
                    scrollWheelZoom: false,
                },
                icon: {
                    type: [
                        "/map_icon/pin-food.png",
                        "/map_icon/pin-bag.png",
                        "/map_icon/pin-forest.png",
                        "/map_icon/pin-bus.png",
                        "/map_icon/pin-money.png",
                    ],
                    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
                    iconSize: [35, 45],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41]
                },
                markerList: [],
            },
        }
    },
    methods: {
        changeMode: function (mode) {
            this.mode = mode;
        },
        track: function (type, typeName) {
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
            if (type == '店頭-搜尋物件') {
                setTimeout(() => {
                    $('.btn-primary').blur()
                }, 500);
            }
        },
        hideCollapse: function () {
            $('#accordionMobile button.active').click();
        },
        handleOpenSelect: function (num) {
            let dropOpen = document.querySelector('.dropdown-wrapper');
            for (let x = 1; x <= 7; x++) {
                if (x != num) {
                    $('.dropdown-' + x).removeClass('dropOpen');
                }
            }
            if (event) {
                event.currentTarget.parentNode.classList.toggle('dropOpen')
            }
        },
        changeCategory: function (category) {
            this.category = category;
            this.search(0);
        },
        getTown: function (city) {
            for (let x = 0; x < 4; x++) {
                for (let y = 0; y < this.cityList[x].length; y++) {
                    this.cityList[x][y].active = '';
                    this.cityList[x][y].selected = '';
                    if (city.city == this.cityList[x][y].city) {
                        this.cityList[x][y].active = ' active';
                    }
                }
            }

            for (let x = 0; x < 4; x++) {
                for (let y = 0; y < this.cityList[x].length; y++) {
                    this.cityList[x][y].active = '';
                    this.cityList[x][y].selected = '';
                    if (city.city == this.cityList[x][y].city) {
                        this.cityList[x][y].active = ' active';
                    }
                }
            }

            this.townList = city.town;
            this.checkedCity = city.city;
            this.checkCitySelected();
            this.checkTownUnlimited();
            if (this.width < 768) {
                this.$refs.townList.click();
            }
        },
        checkCitySelected: function () {
            let addon = 0;
            this.countSelected = 0;
            for (let x = 0; x < 4; x++) {
                for (let y = 0; y < this.cityList[x].length; y++) {
                    this.cityList[x][y].selected = '';
                    for (let z = 0; z < this.cityList[x][y].town.length; z++) {
                        for (let index = 0; index < this.checkedTown.length; index++) {
                            // if (this.checkedTown[index] == this.cityList[x][y].town[z]) {
                            if (this.checkedTown[index] == this.cityList[x][y].town[z] && this.cityList[x][y].selected != ' selected') {
                                if (this.cityList[x][y].active == '') {
                                    this.cityList[x][y].selected = ' selected';
                                    this.countSelected++;
                                } else {
                                    addon = 1;
                                }
                                break;
                            }
                        }
                    }
                }
            }
            this.countSelected += addon;
        },
        checkTownUnlimited: function () {
            let townListLength = this.townList.length;
            let checkedLength = 0;
            for (let x = 0; x < townListLength; x++) {
                for (let y = 0; y < this.checkedTown.length; y++) {
                    if (this.townList[x] == this.checkedTown[y]) {
                        checkedLength++;
                        break;
                    }
                }
            }
            this.townUnlimited = townListLength == checkedLength;
        },
        changeTownUnlimited: function () {
            setTimeout(() => {
                for (let x = 0; x < this.townList.length; x++) {
                    for (let y = 0; y < this.checkedTown.length; y++) {
                        if (this.townList[x] == this.checkedTown[y]) {
                            this.checkedTown.splice(y, 1);
                            break;
                        }
                    }
                    if (this.townUnlimited == 1) {
                        this.checkedTown.push(this.townList[x]);
                    }
                }
                this.search(0);
            }, 50);
        },
        changeTown: function () {
            setTimeout(() => {
                this.checkTownUnlimited();
                this.checkCitySelected();
                this.search(0);
            }, 50);
        },
        changeCommunityType: function () {
            this.search(0);
        },
        changePrice: function () {
            this.search(0);
        },
        focusPriceOther: function () {
            for (let x = 0; x < this.checkedPrice.length; x++) {
                if (this.checkedPrice[x] == 9) {
                    return false;
                }
            }
            this.checkedPrice.push(9);
        },
        changeAveragePrice: function () {
            this.search(0);
        },
        focusAveragePriceOther: function () {
            for (let x = 0; x < this.checkedAveragePrice.length; x++) {
                if (this.checkedAveragePrice[x] == 6) {
                    return false;
                }
            }
            this.checkedAveragePrice.push(6);
        },
        focusAgeOther: function () {
            for (let x = 0; x < this.checkedAge.length; x++) {
                if (this.checkedAge[x] == 6) {
                    return false;
                }
            }
            this.checkedAge.push(6);
        },
        changeChecked: function () {
            this.search(0);
        },
        searchTag: function (key, value) {
            this.searchTagList.push({
                key: key,
                value: value
            })
        },
        deleteTag: function (index) {
            let tag = this.searchTagList[index];
            switch (tag.key) {
                case 'keyword':
                    this.keyword = '';
                    break;
                case 'city':
                    let cityName = tag.value.split('全區')[0];
                    for (let x = 0; x < 4; x++) {
                        for (let y = 0; y < this.cityList[x].length; y++) {
                            if (this.cityList[x][y].city != cityName) {
                                continue;
                            }
                            for (let z = 0; z < this.cityList[x][y].town.length; z++) {
                                for (let index = 0; index < this.checkedTown.length; index++) {
                                    if (this.checkedTown[index] == this.cityList[x][y].town[z]) {
                                        this.checkedTown.splice(index, 1);
                                    }
                                }
                            }
                        }
                    }
                    this.checkTownUnlimited();
                    break;
                case 'town':
                    for (let i = 0; i < this.checkedTown.length; i++) {
                        if (this.checkedTown[i] == tag.value) {
                            this.checkedTown.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'communityType':
                    for (let i = 0; i < this.checkedCommunityType.length; i++) {
                        if (this.checkedCommunityType[i] == tag.value) {
                            this.checkedCommunityType.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'price':
                    for (let i = 0; i < this.checkedPrice.length; i++) {
                        if (this.checkedPrice[i] == tag.index) {
                            if (tag.index == 9) {
                                this.priceS = '';
                                this.priceE = '';
                            }
                            this.checkedPrice.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'averagePrice':
                    for (let i = 0; i < this.checkedAveragePrice.length; i++) {
                        if (this.checkedAveragePrice[i] == tag.index) {
                            if (tag.index == 6) {
                                this.averagePriceS = '';
                                this.averagePriceE = '';
                            }
                            this.checkedAveragePrice.splice(i, 1);
                            break;
                        }
                    }
                    break;
                case 'age':
                    for (let i = 0; i < this.checkedAge.length; i++) {
                        if (this.checkedAge[i] == tag.index) {
                            if (tag.index == 6) {
                                this.ageS = '';
                                this.ageE = '';
                            }
                            this.checkedAge.splice(i, 1);
                            break;
                        }
                    }
                    break;
            }
            this.search(0);
        },
        changeOrderBy: function (index) {
            this.orderBy = index;
            this.search(0);
        },
        search: function (page, allow) {
            let pageCount = 16;
            if (this.currentPage == page && page != 0 && allow == undefined) {
                return false;
            }
            setTimeout(() => {
                this.searchTagList = [];
                if (this.keyword != '') {
                    this.searchTagList.push({
                        key: 'keyword',
                        value: this.keyword,
                    });
                }
                let checkedTown = [];
                for (let x = 0; x < this.checkedTown.length; x++) {
                    checkedTown.push(this.checkedTown[x]);
                }
                for (let x = 0; x < 4; x++) {
                    for (let y = 0; y < this.cityList[x].length; y++) {
                        let townCount = 0;
                        for (let z = 0; z < this.cityList[x][y].town.length; z++) {
                            for (let index = 0; index < checkedTown.length; index++) {
                                if (this.cityList[x][y].town[z] == checkedTown[index]) {
                                    townCount++;
                                }
                            }
                        }
                        if (this.cityList[x][y].town.length == townCount) {
                            this.searchTagList.push({
                                key: 'city',
                                value: this.cityList[x][y].city + '全區',
                            });

                            for (let z = 0; z < this.cityList[x][y].town.length; z++) {
                                for (let index = 0; index < checkedTown.length; index++) {
                                    if (this.cityList[x][y].town[z] == checkedTown[index]) {
                                        checkedTown.splice(index, 1);
                                    }
                                }
                            }
                        }
                    }
                }
                // if (this.checkedCity != 0 && this.townUnlimited == 1) {
                //     this.searchTagList.push({
                //         key: 'city',
                //         value: this.checkedCity + '全區',
                //     });
                // }
                // if (this.townUnlimited == 0 && this.checkedTown.length > 0) {
                for (let i = 0; i < checkedTown.length; i++) {
                    this.searchTagList.push({
                        key: 'town',
                        value: checkedTown[i],
                    });
                }
                if (this.checkedCommunityType.length > 0) {
                    for (let i = 0; i < this.checkedCommunityType.length; i++) {
                        this.searchTagList.push({
                            key: 'communityType',
                            value: this.checkedCommunityType[i],
                        });
                    }
                }
                if (this.checkedPrice.length > 0) {
                    for (let i = 0; i < this.checkedPrice.length; i++) {
                        if (this.checkedPrice[i] == 9) {
                            let otherName = '';
                            if (this.priceS != '' && this.priceE != '') {
                                this.searchTagList.push({
                                    key: 'price',
                                    value: otherName + this.priceS + '~' + this.priceE + '萬',
                                    index: this.checkedPrice[i],
                                });
                            } else if (this.priceS != '') {
                                this.searchTagList.push({
                                    key: 'price',
                                    value: otherName + this.priceS + '萬以上',
                                    index: this.checkedPrice[i],
                                });
                            } else if (this.priceE != '') {
                                this.searchTagList.push({
                                    key: 'price',
                                    value: otherName + this.priceE + '萬以下',
                                    index: this.checkedPrice[i],
                                });
                            }
                        } else {
                            this.searchTagList.push({
                                key: 'price',
                                value: this.priceList[this.checkedPrice[i] - 1],
                                index: this.checkedPrice[i],
                            });
                        }
                    }
                }
                if (this.checkedAveragePrice.length > 0) {
                    for (let i = 0; i < this.checkedAveragePrice.length; i++) {
                        if (this.checkedAveragePrice[i] == 6) {
                            let otherName = '';
                            if (this.averagePriceS != '' && this.averagePriceE != '') {
                                this.searchTagList.push({
                                    key: 'averagePrice',
                                    value: otherName + this.averagePriceS + '~' + this.averagePriceE + '萬',
                                    index: this.checkedAveragePrice[i],
                                });
                            } else if (this.averagePriceS != '') {
                                this.searchTagList.push({
                                    key: 'averagePrice',
                                    value: otherName + this.averagePriceS + '萬以上',
                                    index: this.checkedAveragePrice[i],
                                });
                            } else if (this.averagePriceE != '') {
                                this.searchTagList.push({
                                    key: 'averagePrice',
                                    value: otherName + this.averagePriceE + '萬以下',
                                    index: this.checkedAveragePrice[i],
                                });
                            }
                        } else {
                            this.searchTagList.push({
                                key: 'averagePrice',
                                value: this.averagePriceList[this.checkedAveragePrice[i] - 1],
                                index: this.checkedAveragePrice[i],
                            });
                        }
                    }
                }
                if (this.checkedAge.length > 0) {
                    for (let i = 0; i < this.checkedAge.length; i++) {
                        if (this.checkedAge[i] == 6) {
                            let otherName = '';
                            if (this.ageS != '' && this.ageE != '') {
                                this.searchTagList.push({
                                    key: 'age',
                                    value: otherName + this.ageS + '~' + this.ageE + '年',
                                    index: this.checkedAge[i],
                                });
                            } else if (this.ageS != '') {
                                this.searchTagList.push({
                                    key: 'age',
                                    value: otherName + this.ageS + '年以上',
                                    index: this.checkedAge[i],
                                });
                            } else if (this.ageE != '') {
                                this.searchTagList.push({
                                    key: 'age',
                                    value: otherName + this.ageE + '年以下',
                                    index: this.checkedAge[i],
                                });
                            }
                        } else {
                            this.searchTagList.push({
                                key: 'age',
                                value: this.ageList[this.checkedAge[i] - 1],
                                index: this.checkedAge[i],
                            });
                        }
                    }
                }
                if (this.width > 767) {
                    $.each($('.btn-tab'), function (k, v) {
                        if (!$(this).hasClass('collapsed')) {
                            $(this).click();
                        }
                    });
                    pageCount = 16
                } else {
                    pageCount = 6;
                }
                if (page == 0) {
                    page = 1;
                }
                this.currentPage = page;
                let param = {
                    category: this.category,
                    // city: this.checkedCity,
                    town: this.checkedTown,
                    // townUnlimited: this.townUnlimited,
                    communityType: this.checkedCommunityType,
                    price: this.checkedPrice,
                    priceS: this.priceS,
                    priceE: this.priceE,
                    averagePrice: this.checkedAveragePrice,
                    averagePriceS: this.averagePriceS,
                    averagePriceE: this.averagePriceE,
                    keyword: this.keyword,
                    age: this.checkedAge,
                    ageS: this.ageS,
                    ageE: this.ageE,
                    isSale: this.isSale,
                    orderBy: this.orderBy,
                    page: page,
                    pageCount: pageCount,
                }
                this.axios.post('/api/communities/search', param)
                    .then(response => (
                        this.communityList = response.data.communityList,
                            this.areaList = response.data.areaList,
                            this.paginate = response.data.paginate,
                            this.totalRecord = response.data.totalRecord,
                            window.history.pushState('page2', '社區大樓', '/community' + response.data.query),
                            this.resetMarkerList()
                    ))
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                    });
            }, 200);
        },
        clear: function () {
            this.isSale = [];
            this.orderBy = 0;
            this.checkedCity = 0;
            this.checkedTown = [];
            this.townList = [];
            this.townUnlimited = 0;
            this.checkedCommunityType = [];
            this.checkedPrice = [];
            this.priceS = '';
            this.priceE = '';
            this.checkedAveragePrice = [];
            this.averagePriceS = '';
            this.averagePriceE = '';
            this.checkedAge = [];
            this.ageS = '';
            this.ageE = '';
            this.keyword = '';
            this.search(0);
            setTimeout(() => {
                $('.clear-all').blur()
            }, 500)
        },
        resetMarkerList: function () {
            this.leaflet.markerList = [];
            this.marker = L.markerClusterGroup({
                iconCreateFunction: function (cluster) {
                    var clusterSize = "small";
                    if (cluster.getChildCount() < 10000) {
                        clusterSize = "small";
                    }
                    return new L.DivIcon({
                        html: '<div><span>' + cluster.getChildCount() + '</span></div>',
                        className: 'marker-cluster marker-cluster-' + clusterSize,
                        iconSize: new L.Point(40, 40)
                    });
                }
            });
            this.marker.on('clusterclick', (event) => {
                // this.map.setZoom(18);
                this.showItem = [];
                let markerList = event.layer.getAllChildMarkers();
                for (let x = 0; x < markerList.length; x++) {
                    console.log(markerList[x].options)
                    console.log(markerList[x].options.id)
                    this.showItem.push(markerList[x].options.id);
                }
                setTimeout(() => {
                    this.map.setView(event.latlng, 17);
                }, 100)
            });
            // console.log(this.houseList.length)
            for (let x = 0; x < this.communityList.length; x++) {
                let item = this.communityList[x];
                let marker = {
                    type: 0,
                    name: item.title,
                    latLng: [item.lat, item.lng],
                };
                this.leaflet.markerList.push(marker);

                // L.marker([item.lat, item.lng], {title: item.subject}).addTo(this.map)
                //     .bindPopup('A pretty CSS3 popup.<br> Easily customizable.')
                //     .openPopup();
                let markerItem = L.marker([item.lat, item.lng], {
                    id: item.id,
                    title: item.title,
                    icon: L.icon({
                        iconUrl: '/map_icon/pin.png',
                        iconSize: [21, 25],
                        // ...
                    })
                }).on('click', this.markerOnClick)
                markerItem.bindPopup(item.title, {
                    offset: [0, 2],
                    closeButton: false,
                });
                // markerItem.on('click', function () {
                //     alert('Clicked on a member of the group!');
                // })
                this.marker.addLayer(markerItem);
                if (x == 0) {
                    this.map.setView([item.lat, item.lng], this.leaflet.zoom)
                }
            }
            this.map.addLayer(this.marker);
        },
        markerOnClick: function (event) {
            this.markerChecked = event.sourceTarget.options.id;
            setTimeout(() => {
                $('.map-list-group').scrollTop($('.map-list-group').scrollTop() + $('.checked').position().top);
            }, 100);
        },
        zoomIn: function (event) {
            this.map.setZoom(18);
            setTimeout(() => {
                this.map.setView(event.latlng, 18);
            }, 100)
        },
        start: function () {
            let house = this;
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            let category = params.get("category");
            if (category != null) {
                this.category = category;
                if (this.width > 767) {
                    switch (parseInt(category)) {
                        case 0:
                            $('#searchAll-tab').click()
                            break;
                        case 1:
                            $('#searchHouse-tab').click()
                            break;
                        case 2:
                            $('#searchLand-tab').click()
                            break;
                    }
                } else {
                    switch (parseInt(category)) {
                        case 0:
                        case 1:
                        case 2:
                            $('#category' + category).click()
                            break;
                    }
                }
            } else {
                if (this.width > 767) {
                    $('#searchAll-tab').click();
                } else {
                    $('#category0').prop('checked', true)
                }
            }
            let city = params.get("city");
            if (city != null) {
                this.checkedCity = city;
                for (let region = 0; region < 4; region++) {
                    $.each(this.cityList[region], function (k, v) {
                        if (v.city == city) {
                            house.getTown(v);
                        }
                    })
                }
            }
            let url = new URL(window.location);
            // let townUnlimited = params.get("townUnlimited");
            // if (townUnlimited != null) {
            //     this.townUnlimited = townUnlimited;
            // }
            let keyword = params.get("keyword");
            if (keyword != null) {
                this.keyword = keyword;
            }
            let town = url.searchParams.getAll("town[]");
            if (town != null) {
                this.checkedTown = town;
            }
            let communityType = url.searchParams.getAll("community_type[]");
            if (communityType != null) {
                this.checkedCommunityType = communityType;
            }
            let price = url.searchParams.getAll("price[]");
            if (price != null) {
                this.checkedPrice = price;
                let priceS = params.get("priceS");
                if (priceS != null) {
                    this.priceS = priceS;
                }
                let priceE = params.get("priceE");
                if (priceE != null) {
                    this.priceE = priceE;
                }
            }
            let averagePrice = url.searchParams.getAll("averagePrice[]");
            if (averagePrice != null) {
                this.checkedAveragePrice = averagePrice;
                let averagePriceS = params.get("averagePriceS");
                if (averagePriceS != null) {
                    this.averagePriceS = averagePriceS;
                }
                let averagePriceE = params.get("averagePriceE");
                if (averagePriceE != null) {
                    this.averagePriceE = averagePriceE;
                }
            }
            let age = url.searchParams.getAll("age[]");
            if (age != null) {
                this.checkedAge = age;
                let ageS = params.get("ageS");
                if (ageS != null) {
                    this.ageS = ageS;
                }
                let ageE = params.get("ageE");
                if (ageE != null) {
                    this.ageE = ageE;
                }
            }
            let isSale = url.searchParams.getAll("isSale[]");
            if (isSale != null) {
                this.isSale = isSale;
            }
            let orderBy = params.get("orderBy");
            if (orderBy != null) {
                this.orderBy = orderBy;
            }
            let page = params.get("page");
            if (page != null) {
                this.currentPage = page;
                house.search(this.currentPage, true)
            } else {
                house.search(this.currentPage)
            }
        }
    },
    mounted() {
        let house = this;
        this.width = $(window).width();
        this.axios.get('/api/communities/init')
            .then(response => (
                this.siteName = response.data.siteName,
                    this.meta = response.data.meta,
                    this.about = response.data.about,
                    this.image = response.data.image,
                    this.regionList = response.data.regionList,
                    this.cityList = response.data.cityList,
                    this.communityTypeList = response.data.communityTypeList,
                    this.priceList = response.data.priceList,
                    this.averagePriceList = response.data.averagePriceList,
                    this.ageList = response.data.ageList,
                    this.orderByList = response.data.orderByList,
                    document.title = this.meta.title,
                    $("meta[property='og:title']").attr("content", this.meta.title),
                    document.getElementsByTagName('meta')["description"].content = this.meta.description,
                    $("meta[property='og:description']").attr("content", this.meta.description),
                    this.start()
            ))
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
        $('.flex-column').click(function () {
            house.townList = [];
        });

        $("body").on("click", ".region-list button", function (event) {
            house.region = $(this).attr('region');
        });

        $("body").on("click", ".community-lists-view .page-link", function (event) {
            let page = $(this).attr('page');
            house.search(page);

            setTimeout(() => {
                window.scrollTo(0, document.querySelector("#totalRecord").offsetTop);
            }, 500)
        });

        $(document).click(function (event) {
            if ($(event.target).prop('tagName') != 'path'
                && $(event.target).prop('tagName') != 'svg'
                && $(event.target).attr('class') != 'icon'
                && $(event.target).attr('class') != 'btn btn-select'
                && $(event.target).attr('class') != 'btn btn-select selected'
                && $(event.target).closest('.select-dropdown').length == 0) {
                $('.dropOpen').removeClass('dropOpen');
            }
        });

        $(window).resize(function () {
            let nowWidth = $(window).width();
            house.width = nowWidth;
        });

        this.map = L.map('map').setView(this.leaflet.center, this.leaflet.zoom);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        this.map.on('zoomend', () => {
            // console.log(this.map.getZoom())
            if (this.map.getZoom() == 13) {
                this.map.removeLayer(this.marker);
                for (let x = 0; x < this.areaList.length; x++) {
                    let area = this.areaList[x];
                    let areaIcon = L.divIcon({
                        html: '<div><div>' + area.town + '<br>' + area.count + '</div></div>',
                        className: 'marker-cluster-area',
                        iconSize: new L.Point(70, 70),
                    });
                    this.divIcon.push(L.marker(area.center, {icon: areaIcon}).on('click', this.zoomIn).addTo(this.map));
                }
            } else if (this.map.getZoom() > 13 && this.divIcon.length > 0) {
                for (let x = 0; x < this.divIcon.length; x++) {
                    this.map.removeLayer(this.divIcon[x]);
                }
                this.divIcon = [];
                this.resetMarkerList()
            }
        });
    }
}
</script>
